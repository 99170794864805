import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  autoRepairHero,
  autoRepairSubHero,
  autoRepairsWhiteGlove,
  autoRepairLargeFeatureA,
  autoRepairTrusted,
  autoRepairMultiSection,
  autoRepairCTA,
  autoRepairVideo,
  // autoRepairTestimonials,
  autoRepairArticles,
  autoRepairCar,
  // autoRepairFaqs,
} from "../../../data/auto-repair-data";
// import NoVisualsCustom from '../../../components/NoVisuals/NoVisualsCustom';
// import WhiteGlove from '../../../components/WhiteGlove/WhiteGlove';
// import LargeFeatures from '../../../components/LargeFeatures/LargeFeatures';
// import TrustedNumbers from '../../../components/TrustedNumbers/TrustedNumbers';
// import LargeCta from '../../../components/CTAs/LargeCta';
// import SuccessStories from '../../../components/SuccessStories/SuccessStories';
// import VideoSection from '../../../components/VideoSection/VideoSection';
// import Articles from '../../../components/Articles/Articles';
// import BusinessTypes from '../../../components/BusinessTypes/BusinessTypes';
import {
  heroSuccess,
  NorthEastAutoSlides,
} from "../../../data/success-stories-data";
// import { tempData } from '../../data/article-data';
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import { autoPartsCar } from '../../data/auto-parts-data';
// import Faqs from '../../components/FAQs/Faqs';

import heroBg from "../../../images/hero-images/auto-repair.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "auto-repair-wg.png";

const AutoRepair = () => {
  return (
    <Layout>
      <SEO
        title=" Auto Repair Shop POS System | Point of Sale Software |  SpotOn"
        description="SpotOn's point of sale software helps auto repair shops be more efficient & organized. Get paid faster with payment processing & appointment scheduling. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/automotive/auto-repair/"
      />
      <BHero sectionData={autoRepairHero} heroBg="auto-repair.png" />
      <NoVisualsCustom sectionData={autoRepairSubHero} complex />
      <WhiteGlove
        sectionData={autoRepairsWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={autoRepairLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={autoRepairTrusted} />
      <LargeFeatures
        sectionData={autoRepairMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={autoRepairCTA} />
      <SuccessStories sectionData={heroSuccess} slides={NorthEastAutoSlides} />
      <VideoSection sectionData={autoRepairVideo} />
      {/* <TestmonialReviews sectionData={autoRepairTestimonials} /> */}
      <Articles
        sectionData={autoRepairArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:auto",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={autoRepairCar} />

      {/* <Faqs sectionData={autoRepairFaqs} /> */}
    </Layout>
  );
};

export default AutoRepair;
